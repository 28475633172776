<template>
  <v-container class="ps-6">
    <h1>Allgemeine Geschäftsbedingungen</h1>
    <h2>Präambel</h2>
    <p>
      Betreiber dieser Plattform ist Benedikt Binzer, Hans-Riggenbach-Straße 8 in 65795 Hattersheim am Main (nachfolgend
      „WEDDING-FLOW.DE “). WEDDING-FLOW.DE ist eine Webanwendung im B2B Bereich speziell für Hochzeitsplaner. Die
      Plattform kann standortunabhängig auch als digitales Planungstool genutzt werden. Die online Präsenz ist im Web oder
      auch als App verfügbar.
    </p>
    <h2>§ 1 Geltungsbereich</h2>
    <ol>
      <li>
        Diese Allgemeinen Geschäftsbedingungen (nachfolgend “AGB”) finden zwischen Ihnen als Hochzeitsplaner und
        WEDDING-FLOW.DE („uns“ oder „wir“) Anwendung. Hiermit wird der Einbeziehung von eigenen Bedingungen von Ihnen
        widersprochen, es sei denn, es ist etwas anderes vereinbart.
      </li>
      <li>
        Hinweise auf die Geltung gesetzlicher Vorschriften haben nur klarstellende Bedeutung. Auch ohne eine derartige
        Klarstellung gelten daher die gesetzlichen Vorschriften, soweit sie in diesen AGB nicht unmittelbar abgeändert
        oder ausdrücklich ausgeschlossen werden.
      </li>
    </ol>
    <h2>§ 2 Beschreibung des Dienstes</h2>
    <ol>
      <li>
        WEDDING-FLOW.DE stellt eine Plattform, der es dem Hochzeitsplaner ermöglicht, über die Plattform Hochzeiten zu
        planen und zu koordinieren.
      </li>
      <li>
        Das auf der Plattform veröffentlichte Angebot wird in Ihren Namen, ausgehend von den bereitgestellten
        Informationen, veröffentlicht. WEDDING-FLOW.DE überprüft nicht die Richtigkeit oder Vollständigkeit der
        Informationen und ist nicht verantwortlich für die Durchführung des Vertrags zwischen dem Hochzeitsplaner und
        seinen Kunden.
      </li>
    </ol>


    <h2> § 3 Vertragsschluss und Nutzungsbedingungen</h2>
    <ol>
      <li>
        Ein Vertragsschluss zwischen dem Hochzeitsplaner und WEDDING-FLOW.DE findet über den externen Anbieter „Stripe“
        statt.
      </li>
      <li>
        WEDDING-FLOW.DE stellt dem Ihnen den Service zur Verfügung die Plattform zur Planung von Hochzeiten zu nutzen.
        In diesem Zusammenhang werden entsprechende Vereinbarungen getroffen. Die Rolle von WEDDING-FLOW.DE beim
        Abschluss von Vereinbarungen ist die eines Vermittlers im Namen des Hochzeitsplaners.
      </li>
      <li>
        WEDDING-FLOW.DE gestattet dem Hochzeitsplaner Daten auf der Plattform selbst zu ändern.
      </li>
      <li>
        WEDDING-FLOW.DE ermöglicht es relevante Daten, wie die verarbeiteten Bestellungen, die geschuldeten Zahlungen,
        Rechnungen und Online-Zahlungen, veröffentlichen.
      </li>
      <li>
        Mit Ausnahme des Logos bzw. der CI des Hochzeitsplaners verbleiben alle geistigen Eigentumsreche bei
        WEDDING-FLOW.DE .
      </li>
      <li>
        Der Hochzeitsplaner ist allein verantwortlich für die Abwicklung von Kundenbeschwerden hinsichtlich der
        Erfüllung von Vereinbarungen.
      </li>
    </ol>


    <h2> § 4 Pflichten und Garantien des Hochzeitsplaners</h2>
    <ol>
      <li>
        Der Hochzeitsplaner wird WEDDING-FLOW.DE alle notwendigen Daten zur Verfügung stellen.
      </li>
      <li>
        Der Hochzeitsplaner ist für die Vollständigkeit und Richtigkeit der Daten verantwortlich.
      </li>
      <li>
        Der Hochzeitsplaner gewährleistet eine Erreichbarkeit per Mail und Telefon während seiner Geschäftszeiten.
      </li>
      <li>
        Der Hochzeitsplaner garantiert die Einhaltung alle gültigen Rechtsvorschriften und Regelungen.
      </li>
    </ol>


    <h2>§ 5 Entgelt für die Nutzung der Plattform</h2>
    <p>
      Für die Nutzung der Plattform fallen monatliche Gebühren (Abo) an und werden über externen Anbieter „Stripe“
      abgewickelt.
    </p>

    <h2>§ 6 Vertragsdauer und Vertragskündigung</h2>

    <ol>
      <li>
        WEDDING-FLOW.DE erbringt den Service für einen unbestimmten Zeitraum ab dem Datum der Annahme der
        Registrierung des Hochzeitsplaners durch WEDDING-FLOW.DE .
      </li>
      <li>
        Die Parteien können den Service unter Einhaltung einer Frist von einem (1) Monat per Klick auf den Button
        „Abo Kündigen“ kündigen.
      </li>
      <li>
        WEDDING-FLOW.DE kann den Service sofort beenden, wenn der Hochzeitsplaner:
        <ul>
          <li>
            gegen Bestimmungen der AGB verstößt,
          </li>
          <li>
            den Zahlungsverpflichtungen nicht nachkommt,
          </li>
          <li>
            im Zug der Registrierung falsche oder unvollständige Daten angibt oder solche Informationen nicht adäquat
            aktualisiert,
          </li>
          <li>
            (wiederholt) negative Bewertungen von Kunden erhält, oder
          </li>
        </ul>
      </li>
    </ol>

    <h2>§ 7 Haftung</h2>
    <ol>
      <li>
        Ansprüche des Kunden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche
        des Kunden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher
        Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder
        grob fahrlässigen Pflichtverletzung von WEDDING-FLOW.DE , seiner gesetzlichen Vertreter oder Erfüllungsgehilfen
        beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags
        notwendig ist.
      </li>
      <li>
        Bei der Verletzung wesentlicher Vertragspflichten haftet WEDDING-FLOW.DE nur auf den vertragstypischen,
        vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um
        Schadensersatzansprüche des Kunden aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.
      </li>
      <li>
        Für Schäden oder Störungen, die auf der Fehlerhaftigkeit oder Inkompatibilität von Soft- oder Hardware der
        Teilnehmer beruhen, sowie für Schäden, die auf Grund der mangelnden Verfügbarkeit oder der Funktionsweise des
        Internets entstanden sind, ist WEDDING-FLOW.DE nicht verantwortlich.
      </li>

    </ol>


    <h2>§ 8 Datenschutz</h2>

    <p>
      Personenbezogene Daten, die erforderlich sind, um ein Vertragsverhältnis mit Ihnen einschließlich seiner
      inhaltlichen Ausgestaltung zu begründen oder zu ändern sowie personenbezogene Daten zur Bereitstellung und
      Erbringung unserer Leistungen verarbeiten wir selbstverständlich nur im Rahmen der geltenden gesetzlichen
      Bestimmungen der DSGVO. Weitere Informationen sind in unseren Datenschutzhinweisen enthalten, die Sie unter
      folgendem Link abrufen können: https://wedding-flow.de/#/datenschutzerklaerung.
    </p>

    <h2>§ 9 Anwendbares Recht und Gerichtsstand, salvatorische Klausel</h2>

    <ol>
      <li>
        Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der kollisionsrechtlichen Regelungen des
        Internationalen Privatrechts sowie unter Ausschluss des Kaufrechts der Vereinten Nationen.
      </li>
      <li>
        Haben Sie keinen allgemeinen Gerichtsstand in Deutschland oder in einem anderen EU-Mitgliedsstaat, oder sind Sie
        Kaufmann oder eine juristische Person des öffentlichen Rechts oder haben Sie Ihren festen Wohnsitz nach
        Einbeziehung
        dieser AGB ins Ausland verlegt oder ist Ihr Wohnsitz oder gewöhnlicher Aufenthaltsort im Zeitpunkt der
        Klageerhebung
        nicht bekannt, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertrag der Sitz von
        WEDDING-FLOW.DE.
      </li>
    </ol>
  </v-container>
</template>
<script>


export default {
  name: 'DataProtection',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
<style>
h2{
  padding-top: 15px;
  padding-bottom: 10px
}

</style>
